<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col cols="3" class="mr-1">
        <v-text-field
          v-model="name"
          :color="color"
          :rules="[max255CharRule, presenceRule, onlyLettersRule]"
          dense
          filled
          label="Nome del servizio"
          hide-details="auto"
          autofocus
        ></v-text-field>
      </v-col>
      <v-col class="mr-1">
        <TagsAutocomplete
          :multiple="false"
          v-model="tags"
          dense
          return-object
          :filters="tagsFilter"
          :rules=[categoriesRule]
          @input="setFields('tags', $event)"
          :bus="bus"
        ></TagsAutocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1 mt-2">
      <v-col class="mr-3" cols="3">
        <ServiceTypeCategoriesAutocomplete
          v-model="serviceType"
          dense
          return-object
          :rules=[categoriesRule]
          @input="setTag"
        ></ServiceTypeCategoriesAutocomplete>
      </v-col>
      <v-col class="mr-1" cols="2">
        <v-text-field
          v-model="price"
          filled
          label="Prezzo"
          dense
          hide-details="auto"
          prepend-icon="mdi-cash-multiple"
          :rules="[mustBeNumberOrUndefined]"
        >
          <template v-slot:append>
            <div class="mt-1 grey--text">€</div>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="ml-3" cols="3" v-if="(!phases || phases.length == 0) || mode === 'edit'" >
        <DurationPicker
          v-model="minutes"
          :color="color"
          placeholder="Durata"
          :dense="false"
          :disabled="!!phases && phases.length > 0"
          :rules="[presenceRule, mustBeNumberOrUndefined]"
        ></DurationPicker>
      </v-col>
      <v-col class="ml-5">
        <template>
          <v-subheader class="font-italic font-weight-light">
            Fasi
            <v-btn icon class="ml-2" @click="$emit('edit-phases')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-subheader>
          <v-timeline
            v-if="!!phases && phases.length > 0" 
            align-top
            dense
            fill-dot
            :color="color"
            class="mt-3"
          >
            <v-timeline-item 
              v-for="phase in phases" 
              :key="phase.id" 
              small
              :color="color"
            >
              <v-row>
                <strong>{{phase.name}}</strong>
              </v-row>
              <v-row>
                {{phase.minutes != undefined && phase.minutes != '' ? humanReadableMinutes(phase.minutes) : 'no minutes'}}
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <span v-else>Nessuna fase</span>
        </template>
      </v-col>
      <v-col class="ml-3" cols="auto">
        <v-checkbox
          v-model="disabled"
          label="Disabilita Servizio"
        ></v-checkbox>
      </v-col>
    </v-row>
  <v-row no-gutters>
      <v-col cols="4">
          <v-textarea
            v-model="description"
            :color="color"
            :rules="[max255CharRule]"
            dense
            filled
            label="Descrizione"
            hide-details="auto"
          ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import serviceForm from '@/services/services/service.form.js'
import ServiceTypesAutocomplete from '@/components/common/ServiceTypeAutocomplete.vue'
import StandardColorPicker from '@/components/common/StandardColorPicker.vue'
import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js"
import dateUtils from '@/mixins/common/dateUtils.js'
import TagsAutocomplete from '@/components/common/TagsAutocomplete.vue'
import ServiceTypeCategoriesAutocomplete from '@/components/common/ServiceTypeCategoriesAutocomplete.vue'
import DurationPicker from '@/components/common/DurationPicker.vue'
import tagsService from '@/services/tags/tags.service.js'

export default {
  name: "GeneralFormTab",
  components: {
    ServiceTypesAutocomplete,
    DurationPicker,
    StandardColorPicker,
    TagsAutocomplete,
    ServiceTypeCategoriesAutocomplete,
  },
  mixins: [
    dateUtils
  ],
  data: function() {
    return {
      name: undefined,
      description: undefined,
      serviceTypeId: undefined,
      minutes: undefined,
      price: undefined,
      phases: [],
      icon: undefined,
      color: undefined,
      loadingService: false,
      formValid: false,
      serviceType: undefined,
      tags: [],
      disabled: false,
      tagsFilter: { typeCost: false },
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      categoriesRule: (v) => {
        if (!!v && v.length > 0)
          return true
        else
          return 'Il campo è obbligatorio'
      },
      mustBeNumberOrUndefined: (value) => {
        if(!!value && value != '') {
          return true
        } else {
          let isnum = /^[\d|.]+$/.test(value);

          if(!isnum) {
            return 'Numero non valido, usa il \'.\' per i numeri decimali'
          } else {
            return true
          }
        }
      },
      onlyLettersRule: (v) => {
        let isnum = /^[a-zA-Z\s\.\d\*\/]+$/.test(v);

        return !!isnum || 'Nessun carattere speciale'
      }
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      },
    },
    mode: {
      type: String,
      default: ''
    },
    viewPhasesList: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.handleObjectChanges(serviceForm.service)
    
    var self = this
    serviceForm.on('update', function(data) {
      self.handleObjectChanges(data.service)
    })
  },
  methods: {
    fields() {
      return ['name', 'description', 'minutes', 'icon', 'color', 'price', 'tags', 'disabled']
    },
    handleObjectChanges(service) {
      const fields = this.fields()
      const newValKeys = Object.keys(service)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && service[field] != this[field]) {
            this[field] = service[field]            
        }
      }
      if (!!service.serviceType && !this.serviceType) {
        this.serviceType = service.serviceType
        this.color = service.serviceType.color
      }
      if (!!service.servicePhases && !this.servicePhases)
        this.phases = service.servicePhases
    },
    fetchServiceTypeCategories() {
      return new Promise((resolve,reject) => {
        serviceTypesCategoriesService.list().then((results) => {
        resolve(results.rows)
      }).catch((err) => {
          reject(err)
      })
      })
    },
    setFields(name, value) {
      serviceForm.updateField(name, value)
    },
    setTag(serviceType) {
      if (this.mode === 'edit') return
      if(!serviceType) return
      tagsService.findeByDescription(serviceType.name).then((response) => {
        this.bus.$emit('update:tags', response)
      })
    }
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      serviceForm.setValid(newVal)
    },
    name(newVal) {
      serviceForm.updateField('name', newVal)
    },
    minutes(newVal) {
      serviceForm.updateField('minutes', newVal)
    },
    description(newVal) {
      serviceForm.updateField('description', newVal)
    },
    icon(newVal) {
      serviceForm.updateField('icon', newVal)
    },
    color(newVal) {
      serviceForm.updateField('color', newVal)
    },
    price(newVal) {
      serviceForm.updateField('price', newVal)
    },
    serviceType(newVal) {
      if (newVal) {
        serviceForm.updateField('serviceTypeId', newVal.id)
        serviceForm.updateField('color', newVal.color)
      }
    },
    disabled(newVal) {
      serviceForm.updateField('disabled', newVal)
    }
  }
};
</script>